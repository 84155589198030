import React, { useEffect, useState } from 'react';

const CounterSection = () => {

  const [countersec, setCounter] = useState([]);

  useEffect(() => {
    fetch(`https://leadone-e460af6c5875.herokuapp.com/counters`)
      .then((res) => res.json())
      .then((info) => setCounter(info));
  }, []);

  return (
    <>

      {
        countersec.map(c => <section className="web_business-section" id="business">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="box">
                  <h6>{c.happyClientsText}</h6>
                  <figure className="business-icon">
                    <img src="https://i.ibb.co/6swr4R0/user.png" className="img-fluid" alt />
                  </figure>
                  <div className="number">
                    <span className="value counter">{c.happyClientsNumber}</span>
                    <sup>+</sup>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="box">
                  <h6>{c.winingAwardText}</h6>
                  <figure className="business-icon">
                    <img src="https://i.ibb.co/6F8SxWH/success.png" className="img-fluid" alt />
                  </figure>
                  <div className="number">
                    <span className="value counter">{c.winingAwardNumber}</span>
                    <sup>+</sup>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="box">
                  <h6>{c.projectCompleteText}</h6>
                  <figure className="business-icon">
                    <img src="https://i.ibb.co/4jjZG73/completed-form.png" className="img-fluid" alt />
                  </figure>
                  <div className="number">
                    <span className="value counter">{c.projectCompleteNumber}</span>
                    <sup>+</sup>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="box">
                  <h6>{c.yearofExperienceText}</h6>
                  <figure className="business-icon">
                    <img src="https://i.ibb.co/L9n4Nsq/quality-1.png" className="img-fluid" alt />
                  </figure>
                  <div className="number">
                    <span className="value counter">{c.yearofExperienceNumber}</span>
                    <sup>+</sup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        )
      }





    </>
  );
};

export default CounterSection;