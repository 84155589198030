import React, { useEffect, useState } from 'react';

const WhyChoose = () => {

  const [choose, setService] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://leadone-e460af6c5875.herokuapp.com/choose`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);

  useEffect(() => {
    fetch(`https://leadone-e460af6c5875.herokuapp.com/choose-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  return (
    <>

      {
        choose.map(c => <section className="web_choose-section" id='whychoose-sec'>
          <div className="container">
            <div className="row">
              <div className="col-12">

                <div className="content">
                  <h5>{c.chooseHeading}</h5>
                  <h2>{c.choosesubHeading}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-md-left text-center">
                <div className="choose_wrapper">
                  <figure className="choose-image mb-0">
                    <img src={c.chooseImg} className="img-fluid" alt />
                  </figure>
                  <figure className="choose-boximage1 mb-0">
                    <img src="./assets/images/contact-boximage1.png" className="img-fluid" alt />
                  </figure>
                  <figure className="choose-boximage2 mb-0">
                    <img src="./assets/images/contact-boximage2.png" className="img-fluid" alt />
                  </figure>
                  <figure className="choose-boximage3 mb-0">
                    <img src="./assets/images/contact-boximage3.png" className="img-fluid" alt />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">

                <div className="choose_contentbox">
                  <div className="upper-row">
                    <div className="row">

                      {
                        items.map(a => a.stepName === '01' && <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="choose-box">
                            <figure className="choose-icon">
                              <img src={a.chooseIcon} alt className="img-fluid" />
                            </figure>
                            <h4>{a.chooseTitle}</h4>
                            <p className="text-size-18">{a.chooseDetails}</p>
                          </div>
                        </div>)}


                      {
                        items.map(b => b.stepName === '02' && <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="choose-box choose-box2">
                            <figure className="choose-icon">
                              <img src={b.chooseIcon} alt className="img-fluid" />
                            </figure>
                            <h4>{b.chooseTitle}</h4>
                            <p className="text-size-18">{b.chooseDetails}</p>
                          </div>
                        </div>)}





                    </div>
                  </div>
                  <div className="row">

                    {
                      items.map(c => c.stepName === '03' &&
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="choose-box">
                            <figure className="choose-icon">
                              <img src={c.chooseIcon} alt className="img-fluid" />
                            </figure>
                            <h4>{c.chooseTitle}</h4>
                            <p className="text3 text-size-18">{c.chooseDetails}</p>
                          </div>
                        </div>)}


                    {
                      items.map(d => d.stepName === '04' && <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="choose-box choose-box2">
                          <figure className="choose-icon">
                            <img src={d.chooseIcon} alt className="img-fluid" />
                          </figure>
                          <h4>{d.chooseTitle}</h4>
                          <p className="text4 text-size-18">{d.chooseDetails}</p>
                        </div>
                      </div>)}



                  </div>
                </div>
                <div className="button">

                </div>
              </div>
            </div>
          </div>
        </section>)
      }




    </>
  );
};

export default WhyChoose;