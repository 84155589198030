import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TestimonialSection from './../../components/HomePage/TestimonialSection';
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const AddTestimonial = () => {


    const [testimonialtext, setTestimonialText] = useState([]);

    const [testimonials, settestimonials] = useState([]);


    useEffect(() => {
        fetch(`https://leadone-e460af6c5875.herokuapp.com/testimonialtext`)
            .then((res) => res.json())
            .then((info) => setTestimonialText(info));
    }, []);

    useEffect(() => {
        fetch(`https://leadone-e460af6c5875.herokuapp.com/testimonials`)
            .then((res) => res.json())
            .then((info) => settestimonials(info));
    }, []);

    const handleTestimonialSection = (event) => {
        event.preventDefault();
        const testimonialText = event.target.testimonialText.value;
        const testimonialHeading = event.target.testimonialHeading.value;


        const testimonialSection = {
            testimonialText,
            testimonialHeading,


        };

        const url = `https://leadone-e460af6c5875.herokuapp.com/add-testimonial-text`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(testimonialSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Service is Updated');
            });
    };





    const handleStepSection = (event) => {
        event.preventDefault();
        const image = event.target.image.value;
        const personName = event.target.personName.value;
        const personTitle = event.target.personTitle.value;
        const desc = event.target.desc.value;



        const stepSection = {
            image,
            personName,
            personTitle,
            desc,

        };

        const url = `https://leadone-e460af6c5875.herokuapp.com/add-testimonial`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(stepSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert('Step Section is Updated');
            });
    };



    return (
        <>
           
            <section id="services" class="services-area pt-120 pb-90 fix mb-100">
                <div className='mt-5'>
                    <div class="container">
                        <div class="row">

                        </div>
                        <div class="row">

                            <div class="col-lg-8 col-md-12">

                                <h3> Update Testimonial Text </h3>
                                {
                                    testimonialtext.length === 1 &&
                                    <>
                                        {testimonialtext.map(text =>
                                            <Link className='btn-start-btn' to={`/testimonialtext-edit/${text._id}`}> Update Now</Link>
                                        )}
                                    </>
                                }

                                {testimonialtext.length === 0 &&
                                    <form class="contact-form " onSubmit={handleTestimonialSection}>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                    <input type="text" id="firstn" name="testimonialText" placeholder=" Testimonial Text" required />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                    <input type="text" id="firstn" name="testimonialHeading" placeholder="Testimonial Heading" required />
                                                </div>
                                            </div>




                                            <div class="slider-btn">
                                                <button class="btn-start-btn" data-animation="fadeInRight" data-delay=".8s"> Update Testimonial Text </button>
                                            </div>
                                        </div>

                                    </form>
                                }





                                {/* <h3 className='mt-5'> Add Testimonial Item </h3>

                                <form class="contact-form " onSubmit={handleStepSection}>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                <input class="form-control" name="image" placeholder="Person Image URL" required />
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                <input class="form-control" name="personName" placeholder="Person Name" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                <input class="form-control" name="personTitle" placeholder="Person Title" required />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="col-lg-12 col-md-12 mb-3 mb-3">
                                                <input class="form-control" name="desc" placeholder="Details" required />
                                            </div>
                                        </div>

                                        <div class="slider-btn">
                                            <button class="btn-start-btn" data-delay=".8s"> Add Testimonial</button>
                                        </div>
                                    </div>

                                </form> */}

                            </div>


                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title center-align mb-5">

                                    <h3 className='mt-5 mb-5'> Your Testimonial Person</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row col-lg-6 ">
                            {
                                testimonials.map(e => <div className="slider-item">
                                    <div className="qoute-icon">

                                    </div>
                                    <div className="inner-text">
                                        <p>
                                            {e.desc}
                                        </p>
                                    </div>
                                    {/* slider Image */}
                                    <div className="client">
                                        <div className="client-info">
                                            <h5><Link to={`/edit-testimonial/${e._id}`} className='btn-start-btn'>Edit Now</Link></h5>
                                            <h5>{e.personName}</h5>

                                            <span>{e.personTitle}</span>
                                        </div>
                                        <div className="client-img mb-5">
                                            <img src={e.image} alt="client-1" />
                                        </div>

                                    </div>
                                </div>)
                            }
                        </div>

                    </div>




                </div>
            </section></>

    );
};

export default AddTestimonial;