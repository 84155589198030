import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://leadone-e460af6c5875.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);


  return (

    <>

    {
      about.map(a=><section className="web_grow-section" id="about-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-6 col-sm-12 col-12 order-md-1 order-2">
            <div className="grow_content">
              <h5>{a.aboutText}</h5>
              <h2>{a.aboutHeading}</h2>
              <p className="text">{a.aboutDetails}
              </p>
              <ul className="list-unstyled">
                <li className="text1"><i className="circle fa-duotone fa-check" />{a.aboutPointOne}</li>
                <li className="text1"><i className="circle fa-duotone fa-check" />{a.aboutPointTwo}</li>
                <li className="text1"><i className="circle fa-duotone fa-check" />{a.aboutPointThree}</li>
                <li className="text1"><i className="circle fa-duotone fa-check" />{a.aboutPointFour}</li>
              </ul>

            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-12 order-md-2 order-1 text-md-left text-center">
            <div className="grow_wrapper position-relative">
              <figure className="grow-image mb-0">
                <img src={a.aboutImg} className="img-fluid" alt />
              </figure>
              <figure className="grow-boximage1 mb-0">
                <img src="./assets/images/testimonial-boximage2.png" className="img-fluid" alt />
              </figure>
              <figure className="grow-boximage2 mb-0">
                <img src="./assets/images/contact-boximage1.png" className="img-fluid" alt />
              </figure>
              <figure className="grow-boximage3 mb-0">
                <img src="./assets/images/contact-boximage3.png" className="img-fluid" alt />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section> )
    }


      









    </>
  );
};

export default AboutSection;
